import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';

export enum PartnerName {
  Flasha = 'flasha',
  Fpma = 'fpma',
  Hillcrest = 'hillcrest',
  SunyDownstate = 'suny-downstate',
  PhoebePutney = 'phoebe-putney',
  DelawareNursesAssociation = 'dna',
}

export enum PartnerType {
  Employer = 'Employer',
  Org = 'Org',
}

export interface PartnerData {
  fullName?: string;
  shortName?: string;
  type?: PartnerType;
  matchesEmployer?: (employerName: string) => boolean;
  supportsStudentLoanApplicationPayrollDeduction?: boolean;
  supportsStudentLoanPaymentViaCardIn12Installments?: boolean;
  downloadPayrollInstructionsLink?: string;
  benefitEligibleTitles?: string[];
  debtConsolidationEnabled?: boolean;
}

export const PARTNER_DATA: Record<PartnerName, PartnerData> = Object.freeze({
  [PartnerName.SunyDownstate]: {
    fullName: 'SUNY Downstate Health Sciences University',
    shortName: 'SUNY Downstate',
    type: PartnerType.Employer,
    matchesEmployer: (employerName: string) =>
      employerName.indexOf('downstate') >= 0 || employerName.indexOf('university physicians of brooklyn') >= 0,
    downloadPayrollInstructionsLink:
      'https://www.downstate.edu/about/our-administration/human-resources/self-service.html',
  },
  [PartnerName.PhoebePutney]: {
    fullName: 'Phoebe Putney',
    shortName: 'Phoebe',
    type: PartnerType.Employer,
    matchesEmployer: (employerName: string) => employerName.indexOf('phoebe') >= 0,
    debtConsolidationEnabled: true,
  },
  [PartnerName.Hillcrest]: {
    fullName: 'Hillcrest Family Services',
    shortName: 'Hillcrest',
    type: PartnerType.Employer,
  },
  [PartnerName.Flasha]: {
    fullName: 'Florida Association of Speech-Language Pathologists and Audiologists',
    shortName: 'FLASHA',
    type: PartnerType.Org,
  },
  [PartnerName.Fpma]: {
    fullName: 'Florida Pediatric Medical Association',
    shortName: 'FPMA',
    type: PartnerType.Org,
  },
  [PartnerName.DelawareNursesAssociation]: {
    fullName: 'Delaware Nurses Association',
    shortName: 'DNA',
    type: PartnerType.Org,
  },
});

export const getPartnerName = (partner: PartnerName) => {
  const index = Object.values(PartnerName).indexOf(partner);
  if (index === -1) {
    return partner
      .split('-')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
      .join(' ');
  }
  const key = Object.keys(PartnerName)[index];
  return key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};

export const getPartnerFromEmployerName = (employerName?: string): PartnerName | undefined => {
  const employerNameLowerCase = (employerName ?? '').toLowerCase();
  return Object.values(PartnerName).find((partnerName) =>
    PARTNER_DATA[partnerName].matchesEmployer?.(employerNameLowerCase),
  );
};

export const usePartnerData = (): PartnerData & { partnerName?: PartnerName } => {
  const { application } = useSelector(getApplicationData);
  const {
    applicationData: { employerName: studentLoanEmployerName },
  } = useSelector(getStudentLoanApplication);

  const employerName = application?.employment.employer1.employerName ?? studentLoanEmployerName;
  const partnerName = getPartnerFromEmployerName(employerName);

  const partnerData = (partnerName ? PARTNER_DATA[partnerName] : null) ?? {};

  return { ...partnerData, partnerName };
};
